.container-fluid-footer {
    /* background-color: #211e3b; */
    background: rgb(42,47,186);
background: linear-gradient(90deg, rgba(42,47,186,1) 0%, rgba(19,35,125,1) 33%, rgba(31,43,253,1) 67%, rgba(145,145,145,1) 100%);
    box-shadow: rgba(18, 18, 31, 0.644) 0px 50px 100px -20px, rgba(255, 254, 254, 0.335) 0px 30px 60px -30px;
}
.derechos {
    text-align: center;
    color: black;
}
.three-section {
    
    color: black;
}
.linea {
    margin-top: -5px;
    background: white;
    border: 1px solid white;
}
.logo-footer {
    height: 100px;
    margin-top: 2%;
    margin-bottom: -1%;
}
.material-icons {
    color: white;
}
.home {
    color: black;
    margin-right: 15px;
}
.home:hover {
    color: orange;
}

.routes {
    color: black;
    margin-right: 15px;
}
.routes:hover {
    color: orange;
}
.us {
    color: black;
    margin-right: 15px;
}
.contact {
    color: black;
    margin-right: 15px;
}
.direccion-footer {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
}
.contacto-footer {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
}
.numero-footer {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
}
.sub-dir {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: black;
}
.sub-num {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: black;
}
.sub-fon {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    color: black;
}
.feliz {
    color: white;
    height: 150px;
    border-color:white;
    margin-top: 2%;
    margin-bottom: -1%;
}

