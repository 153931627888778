.logo-contacto {
  height: 300px;
  margin: 15px;
  
}
.title {
  font-size: 40px;
  font-style: oblique;
  font-family: 'Montserrat', sans-serif;
}
.titulo-contacto {
  font-size: 25px;
  margin-left: 15px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 25px;
}
.letras-contact {
  width: 300px; 
  height: 50px;
  justify-content: center;
  margin-bottom: 1%;
}


/* Start: Google Maps Responsive */
.mapa {
  overflow:hidden;
  padding-bottom:400px; /*Reduce este valor si el mapa fuera muy alto, por ejemplo 250px, puedes usar porcentajes, 50%*/
  position:relative;
  height:0;
}
.mapa iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
/* End: Google Maps Responsive */

.fa-facebook-f:hover {
  color: orange;
}
.fa-instagram:hover {
  color: orange;
}
.fa-whatsapp:hover {
  color: orange;
}
.fa-maxcdn:hover {
  color: orange;
}
.fa-amilia:hover {
  color: orange;
}