.d-flex {
  height: 30px;
}

h3 {
  font-style: oblique;
  text-align: center;
}

h5 {
  text-align: center;
}

.repit {
  color: #ff7505;
}

.common-text {
  margin-right: 15px;
  text-indent: 2rem;
  text-align: justify;
}

.animate__animated.animate__backInLeft {
  --animate-duration: 2s;
}
.animate__animated.animate__backInRight {
  --animate-duration: 2s;
}

/* .socies {
  border: 10px solid transparent;
  border-image: url(../../assets/img-fondo.jpg) 30 stretch;
} */

.parrafo {
  text-indent: 0.5rem;
  text-align: justify;
}

.group {
  border: 10px solid transparent;
  margin-top: 5%;
  border-image: url(../../assets/img-fondo.jpg) 30 stretch;
}


.santiago {
  width: 30%;
  height: auto;
}