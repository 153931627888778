hr {
  border: 1px solid black;
}
.navbar-expand-lg {
  text-decoration: none;
  text-align: center;
  background-color: white;
  display: flex!important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(255, 254, 254, 0.3) 0px 30px 60px -30px;
}
.navbar-collapse {
  justify-content: center;
  font-size: 20px;
}
.navbar-nav .nav-home {
  color: black;
}
.navbar_logo {
  margin-top: 5px;
  margin-left: 85px;
  margin-bottom: 5px;
}
/* .navbar_logo img {
  width: 30%;
} */
.btn-whatsapp {
  display: block;
  width: 40px;
  height: 40px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 100px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}
.btn-messenger {
  display: block;
  width: 50px;
  height: 50px;
  color: #fff;
  position: fixed;
  right: 20px;
  bottom: 170px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  z-index: 999;
}
a {
  margin-right: 25px;
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
}
.fa-home {
  display: none;
}
.fa-shipping-fast {
  display: none;
}
.fa-truck-loading {
  display: none;
}
.fa-user-tie {
  display: none;
}
.fa-phone-volume {
  display: none;
}
.blue-dark {
  background: rgb(131,58,180);
background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(253,38,31,1) 53%, rgba(252,176,69,1) 100%);
}
/* CSS Responsive */

@media (max-width: 998px) {
  .navbar-collapse ul {
    align-items: start;
    margin-left: 30px;
    margin-top: 5px;
  }
  .navbar-collapse li {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .navbar-collapse .home {
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
  }
  .fa-home {
    display: inline-block;
  }
  .fa-shipping-fast {
    display: inline-block;
  }
  .fa-truck-loading {
    display: inline-block;
  }
  .fa-user-tie {
    display: inline-block;
  }
  .fa-phone-volume{
    display: inline-block;
  }
}

.navbar_logo .logo1 {
  width: 500px;
}
.navbar_logo .logo2 {
  display: none;
}
@media (max-width:705px){
  .navbar_logo .logo1 {
    display: none;
  }
  .navbar_logo .logo2 {
    display: block;
    width: 180px;
  }
}