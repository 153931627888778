.common-text-color-light {
  margin-right: 15px;
  text-indent: 2rem;
  text-align: justify;
}

.modal {
  font-size: 12px;
}

.modelo > .contenido .cabeza {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
  color: white;
  background-color: #211e3b;
}

.modelo > .contenido {
  border-radius: 30px;
  align-content: center;
  justify-content: center;
  width: 500px;
  height: 80%;
  background-color: #211e3b;
  padding: 10px 10px;
}

.modelo .descripcion {
  align-content: center;
  justify-content: center;
  color: white;
  margin-right: 12px;
  margin-left: 10px;
  text-indent: 2rem;
  text-align: justify;
}

.rounded {
  height: 150px;
  width: 150px;
}

.img_popup {
  border-radius: 3px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 150px;
  width: 210px;
}

.icons {
  border-radius: 8px;
  filter: grayscale(1);
}

.icons:hover {
  filter: grayscale(0);
  border-radius: 8px;
}

.letras {
  width: 300px; 
  height: 50px;
  justify-content: center;
}
