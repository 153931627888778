/* .card-img-top {
  height: 150px;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 50%;
}
.card {
  border-radius: 8%;
  
}
.card:hover {
  background-color: #dd7d00;
}

.card:hover .text {
  color: white;
}

.card:hover .footer {
  color: white;
}
.card-footer:last-child {
  border-radius: 0 0 calc(2.00rem - 1px) calc(2.00rem - 1px);
} */

.empresa {
  width: 300px; 
  height: 50px;
  margin-top: -20px;
}
.card-img-top {
  width: 130px; 
  height: 130px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}
.servicios {
  width: 10%;
}